<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
      <h4 class="card-title">{{ $t('specialTaskForce.task_force_recommendation_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="division_id"
            >
              <template v-slot:label>
                {{ $t('globalTrans.division') }}
              </template>
              <b-form-select
                plain
                v-model="search.division_id"
                :options="divisionList"
                id="divistion_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="district_id"
            >
              <template v-slot:label>
                {{ $t('globalTrans.district') }}
              </template>
            <b-form-select
              plain
              v-model="search.district_id"
              :options="districtList"
              id="district_id"
            >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="date"
            >
              <template v-slot:label>
                {{ $t('globalTrans.from_date') }}
              </template>
              <date-picker
                id="start_date"
                class="form-control"
                v-model="search.start_date"
                :placeholder="$t('globalTrans.select')"
              >
              </date-picker>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="date"
            >
              <template v-slot:label>
                {{ $t('globalTrans.to_date') }}
              </template>
              <date-picker
                id="end_date"
                class="form-control"
                v-model="search.end_date"
                :placeholder="$t('globalTrans.select')"
              >
              </date-picker>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" style="font-size: 14px;" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <b-row>
      <b-col md="12" v-if="showData">
        <body-card>
          <!-- table section start -->
          <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('specialTaskForce.task_force_recommendation_report') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <a href="javascript:" class="btn-add" @click="pdfExport">
              {{ $t('globalTrans.print') }}
            </a>
            <!-- <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a> -->
          </template>
          <template v-slot:body>
            <b-overlay :show="load">
              <b-row>
                <b-col>
                  <list-report-head :base-url="bazarMonitoringServiceBaseUrl" uri="/configuration/report-head/detail" :org-id="1">
                    <template v-slot:projectNameSlot>
                      {{ }}
                    </template>
                    <u>{{ $t('specialTaskForce.task_force_recommendation_report') }}</u>
                  </list-report-head>
                </b-col>
              </b-row>
              <b-row class="main-search">
                <b-col md="4" class="main-search">
                  {{ $t('globalTrans.division') }}: <strong>{{ searchHeader.division_id ? getColumnName($store.state.CommonService.commonObj.divisionList, searchHeader.division_id) : $t('globalTrans.all') }}</strong>
                </b-col>
                <b-col md="4" class="main-search">
                  {{ $t('globalTrans.district') }}: <strong>{{ searchHeader.district_id ? getColumnName($store.state.CommonService.commonObj.districtList, searchHeader.district_id) : $t('globalTrans.all') }}</strong>
                </b-col>
                <b-col md="4" class="main-search">
                  {{ $t('globalTrans.from_date') }}:
                  <strong>
                    <slot v-if="searchHeader.start_date">
                      {{searchHeader.start_date | dateFormat}}
                    </slot>
                    <slot v-else>
                      {{$t('globalTrans.all')}}
                    </slot>
                  </strong>
                </b-col>
                <b-col md="4" class="main-search">
                  {{ $t('globalTrans.to_date') }}:
                  <strong>
                    <slot v-if="searchHeader.end_date">
                      {{searchHeader.end_date | dateFormat}}
                    </slot>
                    <slot v-else>
                      {{$t('globalTrans.all')}}
                    </slot>
                  </strong>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="table-responsive1">
                  <b-table-simple class="special-task tg mt-3" sticky-header bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                    <b-thead class="bg-primary">
                      <b-tr>
                        <b-th width="7%" class="text-center bg-primary">{{ $t('globalTrans.sl_no') }}</b-th>
                        <b-th width="12%" class="text-center bg-primary">{{ $t('globalTrans.district') }}</b-th>
                        <b-th width="10%" class="text-center bg-primary">{{ $t('priceMonitoring.inspection_date') }}</b-th>
                        <b-th width="15%" class="text-center bg-primary">{{ $t('specialTaskForce.task_force_activities_area') }}</b-th>
                        <b-th class="text-center bg-primary">{{ $t('specialTaskForce.remarks_recommendation') }}</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <slot v-if="datas.length>0">
                        <b-tr v-for="(info, infoIndex) in datas" :key="infoIndex">
                            <b-td class="text-center"  style="vertical-align: top;">{{ $n(infoIndex + 1, { useGrouping: false }) }}</b-td>
                            <b-td class="text-center" style="vertical-align: top;">{{ getColumnName($store.state.CommonService.commonObj.districtList, info.district_id)}}</b-td>
                            <b-td class="text-center" style="vertical-align: top;">{{ info.inspection_date | dateFormat }}</b-td>
                            <b-td class="text-center" style="vertical-align: top;">{{ info.task_force_activities_area }}</b-td>
                            <b-td class="text-left" style="vertical-align: top;">
                              <b-button style="color: black !important;background-color: transparent !important;border: 0px !important;">
                                <span v-html="info.remarks"></span>
                              </b-button>
                            </b-td>
                          </b-tr>
                      </slot>
                      <slot v-else>
                          <b-tr>
                            <b-th colspan="5" class="text-center text-danger">
                              <br/>
                              {{ $t('globalTrans.noDataFound') }}
                              <br/>
                              <br/>
                            </b-th>
                          </b-tr>
                      </slot>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
          <!-- table section end -->
        </body-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import RestApi, { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTask.vue'
import { taskForceRecommendationReport } from '../../api/routes'

export default {
  components: {
    ListReportHead
  },
  data () {
    return {
      sortBy: '',
      search: {
        division_id: 0,
        district_id: 0,
        office_id: 0,
        start_date: '',
        end_date: ''
      },
      datas: [],
      searchHeader: {
        division_id: 0,
        district_id: 0,
        office_id: 0,
        start_date: '',
        end_date: ''
      },
      districtList: [],
      data: [],
      showData: false,
      bazarMonitoringServiceBaseUrl: bazarMonitoringServiceBaseUrl,
      load: false
    }
  },
  watch: {
    'search.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal !== 0) {
          this.districtList = this.getDistrictList(newVal)
        }
      } else {
        this.districtList = []
      }
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    }
  },
  methods: {
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', report_name: 'recommendation', local: this.$i18n.locale, org_id: this.orgId }, this.search)
      this.load = true
      const result = await RestApi.getPdfData(bazarMonitoringServiceBaseUrl, taskForceRecommendationReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.load = false
    },
    getColumnName (list, id) {
      const obj = list.find(item => item.value === parseInt(id))
      return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getDistrictList (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === parseInt(id))
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.showData = true
      this.searchHeader = Object.assign({}, this.search)
      const params = Object.assign({}, this.search)
      this.load = true
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, taskForceRecommendationReport, params)
      if (result.success) {
        this.load = false
        this.datas = result.data
      } else {
        this.datas = []
        this.load = false
      }
    }
  }
}
</script>
<style scoped>
.special-task th {
  font-size: 17px !important;
  padding: 3px;
}
.special-task td {
  padding: 3px;
}
.sub_string{
  width: 150px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  cursor: pointer;
}
.main-search {
  font-size: 15px !important;
}
.main-searchs {
  font-size: 17px !important;
  font-weight: bold !important;
}
.b-table-sticky-header {
  overflow-y: auto !important;
  max-height: 800px !important;
}
.table thead {
  position: sticky;
  top: 0;
}
.position-top-31 {
  top: 31px !important;
}
</style>
